import { Box, Button, ButtonBase, Checkbox, Chip, Dialog, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Tooltip, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import qualityCheckApi, { GlossaryItem, QualityCheckMyGlossaryImportMutationArgs } from "store/apis/qualityCheckApi"
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import axios, { AxiosError } from "axios"
import { fileDownload } from "shared/utils"
import { isEqual } from "lodash";
import { RootState } from "store/store";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

type EditGlossaryPanelProps = {
    isOpen: boolean
    glossary: GlossaryItem[]
    onClickClose: () => void
    expandMyGlossary: () => void
    fileName?: string
    isBuildGlossary: boolean
}

const EditGlossaryPanel = (props: EditGlossaryPanelProps) => {

    const [isEditable, setIsEditable] = useState(false)

    const [myGlossaryItem, setMyGlossaryItem] = useState<QualityCheckMyGlossaryImportMutationArgs>({ glossaryName: "", glossaryItems: [] });

    const [selectedGlossary, setSelectedGlossary] = useState<number[]>([]);

    const [createMyGlossary] = qualityCheckApi.useQualityCheckMyGlossaarImportMutation();

    const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);

    useEffect(() => {
        if (props.fileName) {
            setMyGlossaryItem({ ...myGlossaryItem, glossaryName: props.fileName, glossaryItems: props.glossary })
        } else {
            setMyGlossaryItem({ ...myGlossaryItem, glossaryItems: props.glossary })
        }
    }, [props.glossary])

    const handleDelete = () => {
        setMyGlossaryItem({
            ...myGlossaryItem,
            glossaryItems: myGlossaryItem.glossaryItems.filter((glossary, index) => !selectedGlossary.includes(index))
        });
        setSelectedGlossary([])
    }

    const onClickCheckBox = (index: number) => {
        if (selectedGlossary.includes(index)) {
            setSelectedGlossary(selectedGlossary.filter((selected) => selected !== index))
        } else {
            setSelectedGlossary([...selectedGlossary, index])
        }
    }

    const onClosePanel = () => {
        props.onClickClose()
        setMyGlossaryItem({ glossaryName: "", glossaryItems: [] })
    }

    const handleSaveModification = async() => {
        await createMyGlossary(myGlossaryItem).unwrap().then(() => {
            props.expandMyGlossary()
            props.onClickClose()
            toast.success(`${myGlossaryItem.glossaryItems.length} items successfully added to glossary "${myGlossaryItem.glossaryName}"`)
        }).catch((error) => {
            toast.error(error.data.message)
        })
    }

    const handleExportGlossary = async () => {
        const fileName = myGlossaryItem.glossaryName + ".xlsx"
        // const resultFile = await axios.post(`quality-check/extract-glossary/export`, JSON.stringify(isLoggedIn ? myGlossaryItem.glossaryItems : myGlossaryItem.glossaryItems.slice(0, 5)), {
        const resultFile = await axios.post(`quality-check/extract-glossary/export`, JSON.stringify(myGlossaryItem.glossaryItems), {
            responseType: 'blob',
            headers: {
                "Content-Type": "application/json"
            }
        });
        fileDownload(resultFile.data, fileName)
    }

    const onChangeGlossary = (event: React.ChangeEvent<HTMLInputElement>, lang: string, index: number) => {
        setMyGlossaryItem({
            ...myGlossaryItem, glossaryItems: myGlossaryItem.glossaryItems.map((glossaryItem, i) => {
                if (i === index) {
                    if (lang === "en") {
                        return { ...glossaryItem, en: event.target.value }
                    } else {
                        return { ...glossaryItem, zh: event.target.value }
                    }
                } else {
                    return glossaryItem
                }
            })
        })
    };

    if (isEqual(myGlossaryItem, { glossaryName: "", glossaryItems: [] })) {
        return (<></>)
    } else {
        return (
            <Dialog
                fullWidth
                open={props.isOpen}
                onBackdropClick={onClosePanel}
                PaperProps={{ sx: { borderRadius: "5px", } }}
                onClose={() => { setIsEditable(false); }}
            >
                <Toolbar sx={{ minHeight: "45px" }}>
                    <TextField
                        fullWidth
                        label="Name"
                        inputProps={{ sx: { textAlign: "center", fontWeight: "500" } }}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setMyGlossaryItem({ ...myGlossaryItem, glossaryName: event.target.value }) }}
                        required
                        value={myGlossaryItem.glossaryName}
                    />
                    <Box sx={{ display: "flex" }}>
                        <Tooltip title="modify glossary">
                            <ButtonBase onClick={() => { setIsEditable(!isEditable); setSelectedGlossary([]) }}>

                                <EditIcon />
                            </ButtonBase>
                        </Tooltip>
                        {!isEditable &&
                            <Tooltip title="export glossary">
                                <ButtonBase onClick={handleExportGlossary} disabled={myGlossaryItem.glossaryName === ""}>
                                    <FileDownloadIcon />
                                </ButtonBase>
                            </Tooltip>
                        }
                        {isEditable &&
                            <Tooltip title="delete">
                                <ButtonBase onClick={handleDelete}>
                                    <DeleteIcon />
                                </ButtonBase>
                            </Tooltip>
                        }
                    </Box>
                </Toolbar>
                {/* {props.isBuildGlossary && !isLoggedIn &&
                    <Typography fontSize="1px" textAlign="center">
                        {"*Trial version can only see the 5 glossary items."}
                        <br />
                        {"*Join Addcuracy Plus+ to see full results."}
                    </Typography>
                } */}
                {/* <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>v
                    <Chip label="Date format: 2020年1月1日" />
                    <Chip label="Number format: 100,000,000" />
                </Box> */}
                <TableContainer
                    sx={{
                        maxHeight: "500px",
                        "::-webkit-scrollbar": { width: "5px", transform: "rotate(180deg)" },
                        "::-webkit-scrollbar-thumb": { background: "black" }
                    }}
                >
                    <Table size="small" stickyHeader>
                        <TableHead>
                            <TableRow key="header-row">
                                <TableCell padding="checkbox" />
                                <TableCell sx={{ paddingTop: "0px" }}>{"English"}</TableCell>
                                <TableCell sx={{ paddingTop: "0px" }}>{"Chinese"}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {myGlossaryItem.glossaryItems.map((glossaryItem: GlossaryItem, index: number) => (
                                <TableRow>
                                    <TableCell sx={{ padding: "0px 0px 0px 16px", minWidth: "40px" }}>
                                        {isEditable &&
                                            <Checkbox
                                                checked={selectedGlossary.includes(index)}
                                                onClick={() => onClickCheckBox(index)}
                                                sx={{ padding: "0px" }}
                                            />
                                        }

                                    </TableCell>
                                    <TableCell sx={{ padding: "0px 16px" }}>
                                        <TextField
                                            defaultValue={glossaryItem.en}
                                            // hide glossary for trail user
                                            // defaultValue={(index >= 5 && !isLoggedIn && props.isBuildGlossary) ? glossaryItem.en.replace(/[^\s]/g, "*") : glossaryItem.en}
                                            fullWidth
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChangeGlossary(event, "en", index)}
                                            disabled={!isEditable}
                                            sx={{
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                    WebkitTextFillColor: "black",
                                                },
                                            }}
                                            InputProps={{ style: { fontSize: "0.875rem" }, disableUnderline: isEditable ? false : true }}

                                        />
                                    </TableCell>
                                    <TableCell sx={{ padding: "0px 16px" }}>
                                        <TextField
                                            defaultValue={glossaryItem.zh}
                                            // hide glossary for trial
                                            // defaultValue={(index >= 5 && !isLoggedIn && props.isBuildGlossary) ? glossaryItem.zh.replace(/[^\s]/g, "*") : glossaryItem.zh}
                                            fullWidth
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChangeGlossary(event, "zh", index)}
                                            disabled={!isEditable}
                                            sx={{
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                    WebkitTextFillColor: "black",
                                                },
                                            }}
                                            InputProps={{ style: { fontSize: "0.875rem" }, disableUnderline: isEditable ? false : true }}
                                        />
                                    </TableCell>
                                </TableRow>

                            ))}
                            {isEditable && (
                                <TableRow>
                                    <TableCell colSpan={3} sx={{ padding: "0px 16px" }}>
                                        <ButtonBase
                                            sx={{ fontWeight: "700", width: "-webkit-fill-available", height: "30px" }}
                                            onClick={() =>
                                                setMyGlossaryItem({
                                                    ...myGlossaryItem,
                                                    glossaryItems: [...myGlossaryItem.glossaryItems, { en: "", zh: "" }],
                                                })
                                            }
                                        >
                                            + NEW
                                        </ButtonBase>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Divider />
                <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                <Button onClick={onClosePanel} fullWidth>
                        Close
                    </Button>
                    <Divider />
                    <Button
                        onClick={props.isBuildGlossary && !isLoggedIn ? handleExportGlossary : handleSaveModification}
                        fullWidth
                        disabled={props.isBuildGlossary && !isLoggedIn ?
                            (myGlossaryItem.glossaryName === "")
                            :
                            (myGlossaryItem.glossaryName === "" || myGlossaryItem.glossaryItems.length === 0 || !isLoggedIn)
                        }
                    >
                        {props.isBuildGlossary && !isLoggedIn ? "Export" : "Save"}
                    </Button>
                </Box>
            </Dialog >
        )
    }
}

export default EditGlossaryPanel