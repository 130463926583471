import { Box, Button, IconButton, Typography } from "@mui/material";
import axios from "axios";
import TablePreview from "components/UI/TablePreview";
import { fileDownload } from "shared/utils";
import { PdfToExcelConversionObject } from "types/models/PdfToExcelConversion";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import DownloadIcon from '@mui/icons-material/Download';
import { ArrowForward } from "@mui/icons-material";
import ErrorIcon from '@mui/icons-material/Error';

type CheckSumResultProps = {
    resultTables: PdfToExcelConversionObject[] | undefined
    correctableTables: boolean[]
    colSets: Set<number>[]
    rowSets: Set<number>[]
    onTableCorrectionChangeHandler: (i: number, checked: boolean) => void
    checkSumId: number
    startNewCheckSum: () => void
}

const CheckSumResult = (props: CheckSumResultProps) => {

    const handleDownloadResult = async (table: string[][], formatClasses: number[][], fileName: String) => {
        const resultFile = await axios.post(`/quality-check/check-sum/excel-table/generate`, {
            tables: table,
            formatClasses: formatClasses
        }, { responseType: 'blob' });
        fileDownload(resultFile.data, fileName)
    };

    return (
        props.resultTables === undefined ?
            <Typography>Extracting tables from file...</Typography> :
            <div>
                <Typography style={{ whiteSpace: 'pre-line' }} >
                    {"* Sums checked are highlighted, with correct sums highlighted in GREEN and incorrect sums highlighted in RED.\n"}
                    {"* For vertical sums, the tool guesses which rows are sums, and the tool only checks sums that are the total of CONSECUTIVE numbers immediately above the sum.\n"}
                    {"* For horizontal sums, the tool assumes that only columns that have the word “total” or “subtotal” in their heading are sums, and the tool only checks sums that are the total of CONSECUTIVE numbers immediately to the left of the sum.\n"}
                    {"* A straightforward approach is taken. The sums are recalculated to ensure they are correct. No change is made to the unhighlighted cells.\n"}
                </Typography>
                <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "10px" }}>
                    <Button
                        id="finish-button"
                        onClick={() => props.startNewCheckSum()}
                        style={{ alignItems: "stretch" }}
                        endIcon={<ArrowForward />}
                    >
                        {"finish"}
                    </Button>
                </div>
                {props.resultTables?.map((t, i) => {
                    let isCorrected = false;
                    t.correctedFormatClasses.forEach((row, rowIndex) => {
                        if (row.some((ele => ele === 8 || ele === 7 || ele === 9))) {
                            isCorrected = true;
                        }
                    })
                    return (
                        <div style={{ marginBottom: "10px", border: "1px dashed black", backgroundColor: (props.correctableTables[i] ? "transparent" : "#EFEFEF") }}>
                            <div style={{ borderBottom: "1px dashed black", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <Box>
                                    {t.downloadable &&
                                        <IconButton
                                            sx={{ marginLeft: "10px" }}
                                            onClick={() => handleDownloadResult(t.tables, t.formatClasses, t.fileName)}
                                        >
                                            <DownloadIcon />
                                        </IconButton>
                                    }
                                    <Typography component="div" sx={{ paddingLeft: "10px", display: "inline-block", verticalAlign: "middle" }}>
                                        Preview for Table {t.fileName}:
                                    </Typography>
                                </Box>
                                <Box>
                                    <IconButton
                                        sx={{ marginLeft: "10px" }}
                                        onClick={() => handleDownloadResult(t.correctedTables, t.correctedFormatClasses, t.fileName)}
                                    >
                                        <DownloadIcon />
                                    </IconButton>
                                    <Typography component="div" sx={{ paddingLeft: "10px", display: "inline-block", verticalAlign: "middle" }}>
                                        Preview for corrected Table {`${t!.fileName}`}:
                                    </Typography>
                                </Box>
                            </div>
                            <div style={{ position: "relative", display: "flex", alignItems: "center", justifyContent: "space-around" }}>
                                <div style={{ margin: "10px", border: "2px solid #DFDFDF", display: "inline-block", overflowX: "scroll", maxWidth: "60%", boxSizing: "border-box" }}>
                                    <TablePreview formatClasses={t.formatClasses} table={t.tables} firstNumericColumnIndex={t.firstNumericColumnIndex} />
                                </div>
                                <ArrowRightAltIcon sx={{ fontSize: "40px" }} />
                                {isCorrected ?
                                    <div style={{ margin: "10px", border: "2px solid #DFDFDF", display: "inline-block", overflowX: "scroll", maxWidth: "60%", boxSizing: "border-box" }}>
                                        <TablePreview formatClasses={t!.correctedFormatClasses} table={t!.correctedTables} firstNumericColumnIndex={t!.firstNumericColumnIndex} />
                                    </div>
                                    :
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                                        <ErrorIcon />
                                        {"No sum detected"}
                                    </div>
                                }
                            </div>
                            {i === props.resultTables!.length - 1 &&
                                <Typography variant="h6" style={{ whiteSpace: 'pre-line' }} sx={{ textAlign: "center" }}>
                                    {"Thank you for using CheckSum!\n"}
                                    {"We welcome your feedback and suggestions :)"}
                                </Typography>}
                        </div>
                    )
                })
                }
            </div>
    );

};

export default CheckSumResult;